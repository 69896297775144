import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HowItWorks from '../components/how'
import WhoWeAre from '../components/who'
import ContactUs from '../components/contact'
import OtherServices from '../components/other'

const IndexPage = () => (
  <Layout page="index">
    <SEO title="Rethink Main - Home" />
    <HowItWorks />
    <WhoWeAre />
    <OtherServices />
    <ContactUs />
  </Layout>
)

export default IndexPage