import React from "react"
import build from "../images/icons/build.svg"
import promote from "../images/icons/promote.svg"
import measure from "../images/icons/measure.svg"

const HowItWorks = () => (
  <div id="how" className="section light">
    <div className="container">
      <div className="row sectonTitle">
        <div className="col">
          <h2>How It Works</h2>
        </div>
      </div>

      <div className="row sectonContent">
        <div className="col-md-4 iconBlock">
          <div className="row">
            <div className="col">
              <img alt="build" className="iconLarge" src={build} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h4>Build It</h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="sectionText">
                Establish your identity and credibility with a professional
                business website or ecommerce store.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 iconBlock">
          <div className="row">
            <div className="col">
              <img
                alt="promote"
                className="iconLarge"
                src={promote}
                style={{ padding: "10px 0" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h4>Promote It</h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="sectionText">
                Drive traffic, generate leads or increase sales with a
                comprehensive digital marketing and SEO strategy.
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-4 iconBlock">
          <div className="row">
            <div className="col">
              <img alt="measure" className="iconLarge" src={measure} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h4>Measure It</h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="sectionText">
                Measure outcomes with analytics. Use data to increase
                performance and achieve your goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HowItWorks
