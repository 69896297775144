import React from 'react'
import { Link } from "gatsby"
import MyButton from '../components/button'

const WhoWeAre = () => (
    <div id="who" className="section dark-blue">
        <div className="container">
            <div className="row sectonTitle" >
                <div className="col"><h2>Who We Are</h2></div>
            </div>

            <div className="row sectonContent">
                <div className="col">
                    <div className="row " >
                        <div className="col">
                            <h4>
                                <span className="ourSite" >Rethink Main</span>, a digital agency on a <Link className="ourLinks" to="/about" >mission</Link>.
                            </h4><br/>
                        </div>
                    </div>
                    <div className="row " >
                        <div className="col" >
                            <p className="sectionText">
                            We are a small but dedicated team that seeks to provide digital services to small businesses across America at a fraction of the price of most other agencies.
                            </p>
                            <p className="sectionText">
                            Learn about the <Link className="ourLinks" to="/about#why" >advantages</Link> of working with us and 
                            how your business can benefit from one of our <Link className="ourLinks" to="/pricing" >website packages</Link>. 
                            If you need a custom solution we can create a package for you. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col" style={{margin: '2rem'}} >
                    <MyButton text="Learn More" type="gotoabout" className="blueButton" />
                </div>
            </div>

        </div>
    </div>
);

export default WhoWeAre